export interface IOtherEntry {
  icon: {
    src: string;
    alt: string;
  };
  title: string;
  desc: string;
  link: string;
}

export const OTHER_ENTRY: IOtherEntry[] = [
  {
    icon: {
      src: "https://a.storyblok.com/f/167495/70x70/46416267fd/blog.svg",
      alt: "blog icon",
    },
    title: "Blog",
    desc: "Fresh ideas on productivity, effective meetings,  and more. Delivered.",
    link: "/blog",
  },
  {
    icon: {
      src: "https://a.storyblok.com/f/167495/70x70/275b10b66c/templates.svg",
      alt: "templates icon",
    },
    title: "Templates",
    desc: "Free agenda templates to provide a clear roadmap for every meeting.",
    link: "/meeting-templates",
  },
  {
    icon: {
      src: "https://a.storyblok.com/f/167495/70x70/9e94ad6f42/help-docs.svg",
      alt: "help docs icon",
    },
    title: "Help Docs",
    desc: "Find simple answers to any questions about Airgram.",
    link: "https://help.airgram.io/en/",
  },
];
