import * as React from "react";
import Header from "@/components/head";
import ImagePageNotFound from "@/assets/images/ImagePageNotFound.png";
import * as styles from "./index.module.less";

import {
  Text,
  Heading,
  Link as ChakraLink,
  Image,
  Center,
  Flex,
  Box,
  Link,
} from "@chakra-ui/react";
import Layout from "@layout/index";
import CommonButton from "@/components/common-button";
import { IOtherEntry, OTHER_ENTRY } from "@/modules/404/constants";

const { useRef, useState, useEffect, useMemo, memo } = React;

export const Head = () => {
  return (
    <Header
      title="Airgram 404 | Page Not Found"
      description="Airgram page not found. Please visit https://www.airgram.io and check out one of the best AI meeting assistants to increase productivity."
    />
  );
};

const EntryCard = ({ icon, title, desc, link }: IOtherEntry) => {
  return (
    <Flex
      p={{
        base: "24px",
        sm: "40px",
      }}
      flexDir="column"
      bg="#fff"
      borderRadius={{
        base: "12px",
        sm: "16px",
      }}
      gap={{
        base: "16px",
        sm: "20px",
        lg: "24px",
      }}
      justifyContent="space-between"
    >
      <Flex alignItems="center" gap={{ base: "8px", sm: "12px", lg: "20px" }}>
        <Image
          src={icon.src}
          alt={icon.alt}
          w={{
            base: "40px",
            sm: "48px",
            lg: "56px",
            xl: "64px",
          }}
          h={{
            base: "40px",
            sm: "48px",
            lg: "56px",
            xl: "64px",
          }}
        />
        <Text as="h4" style={{ margin: "0" }}>
          {title}
        </Text>
      </Flex>
      <Text as="p" color="#5B5171" style={{ margin: "0" }} flex="1">
        {desc}
      </Text>
      <Link color="#854EFB" fontWeight="600" href={link}>
        👉 Explore
      </Link>
    </Flex>
  );
};

const NotFoundPage = () => {
  return (
    <Layout isNewStyle px="0" margin="0" maxW="100%" pt="0">
      <Flex
        minH="calc(100vh - 74px)"
        bg="#F4F5FF"
        p={{
          base: "64px 24px",
          sm: "80px 40px",
          lg: "120px 80px",
          "2xl": "120px 160px",
        }}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Flex
          w="full"
          maxW="1600px"
          flexDir="column"
          gap={{
            base: "64px",
            sm: "80px",
            lg: "64px",
          }}
        >
          <Flex
            w="full"
            h="auto"
            className={styles.hero_container}
            flexDir={{
              base: "column-reverse",
              lg: "row",
            }}
            alignItems={{
              base: "center",
              lg: "flex-start",
            }}
          >
            <Flex
              w="full"
              flexDir="column"
              alignItems={{
                base: "center",
                lg: "flex-start",
              }}
              justifyContent="center"
              gap={{
                base: "24px",
                lg: "40px",
              }}
              mt={{
                base: "0px",
                lg: "100px",
                xl: "160px",
              }}
            >
              <Text
                as="h2"
                maxW="760px"
                textAlign={{ base: "center", lg: "left" }}
              >
                Oops... We can’t find the page you’re looking for.
              </Text>
              <CommonButton
                content="Back to Home"
                to="/"
                bg="linear-gradient(90deg, #E146F1 0%, #9047F1 100%);"
                target="_self"
              />
            </Flex>
            <Box
              flexBasis={{
                base: "280px",
                sm: "360px",
                lg: "400px",
                xl: "560px",
              }}
              flexShrink="0"
              w={{
                base: "280px",
                sm: "360px",
                lg: "400px",
                xl: "560px",
              }}
              h={{
                base: "280px",
                sm: "360px",
                lg: "400px",
                xl: "560px",
              }}
            >
              <Image
                w="full"
                h="full"
                maxH="100%"
                flexShrink="0"
                src="https://a.storyblok.com/f/167495/720x642/f1cd52ffb5/image.png"
                alt="page not found image"
                objectFit="contain"
              />
            </Box>
          </Flex>
          <Box
            display="grid"
            gridTemplateColumns={{
              base: "1fr",
              xl: "1fr 1fr 1fr",
            }}
            gap={{
              base: "24px",
              sm: "40px",
            }}
          >
            {OTHER_ENTRY.map((item, index) => {
              return <EntryCard {...item} key={index} />;
            })}
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default memo(NotFoundPage);
